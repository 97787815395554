import React, { useContext, useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Recent from "./Recent";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";

import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Account";
import Settings from "./Settings";
import { Link } from "react-router-dom";
import { LambdaClient, InvokeCommand, LogType } from "@aws-sdk/client-lambda";
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from "@aws-sdk/client-cognito-identity";
import { Buffer } from "buffer";

const Status = (props) => {
  const [status, setStatus] = useState(false);
  const { getSession, logout } = useContext(AccountContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("#first"); // Set initial activeNav state
  const [lambda, setLambda] = useState(null);
  const [user, setUser] = useState(null);
  const [caseList, setCaseList] = useState(null);
  const region = "us-east-1";
  const identityPoolId = "us-east-1:8b2ed160-f875-45e3-9cdc-b53bf3fdc5ac";
  const cognitoClient = new CognitoIdentityClient({ region });

  useEffect(() => {
    // const getSession = async () => {
    //     return await new Promise((resolve, reject) => {
    (async () => {
      const Obj = getSession()
        .then((sessionObj) => {
          if (sessionObj) {
            // console.log("sessionObj", sessionObj.user.username);
            setUser(sessionObj.user.username);
            const currentIdentity = cognitoClient
              .send(
                new GetIdCommand({
                  IdentityPoolId: identityPoolId,
                  Logins: {
                    "cognito-idp.us-east-1.amazonaws.com/us-east-1_U4dN05VIF":
                      sessionObj.idToken.jwtToken,
                  },
                })
              )
              .then((data) => {
                // console.log("IdentityId", data.IdentityId);
                const credentials = cognitoClient
                  .send(
                    new GetCredentialsForIdentityCommand({
                      IdentityId: data.IdentityId,
                      Logins: {
                        "cognito-idp.us-east-1.amazonaws.com/us-east-1_U4dN05VIF":
                          sessionObj.idToken.jwtToken,
                      },
                    })
                  )
                  .then(async (data) => {
                    // console.log("Credentials", data);
                    // setIdentity(data.IdentityId);
                    setLambda(
                      new LambdaClient({
                        region: "us-east-1",
                        credentials: {
                          accessKeyId: data.Credentials.AccessKeyId,
                          secretAccessKey: data.Credentials.SecretKey,
                          sessionToken: data.Credentials.SessionToken,
                        },
                      })
                    );
                  })
                  .catch((err) => {
                    console.error(err, err.stack);
                    setError(err);
                  });
              })
              .catch((err) => {
                setError(err);
              });
          }
        })
        .catch((err) => {
          console.log("getSession error", err);
          setError(err);
        });
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  useEffect(() => {
    (async () => {
      const lambdaParams = {
        FunctionName: "dailyCaseHistory-dev-function1", // the lambda function we are going to invoke
        Payload: JSON.stringify(user), // pass params
        LogType: LogType.Tail,
      };
      try {
        const { Payload, LogResult } = await lambda.send(
          new InvokeCommand(lambdaParams)
        );
        const result = Buffer.from(Payload).toString(); // convert from base64
        // const logs = Buffer.from(LogResult, "base64").toString(); // convert from base64
        // console.log("result", result);
        // console.log("logs", logs);
        // convert result to a JSON object
        const data = JSON.parse(result);
        setCaseList(JSON.parse(data.body));
        // if (data.body !== '{"message":"No case found"}') {
        //   setImage(data.body);
        //   const contentDisposition = data.headers["Content-Disposition"];
        //   // console.log("contentDisposition", contentDisposition);
        //   const regex = /filename=([A-Z]{3,5}-\d{6})/;
        //   const matches = contentDisposition.match(regex);
        //   const fileName = matches ? matches[1] : null;
        //   setResponse(fileName);
        // } else setResponse(data.body);
      } catch (err) {
        console.log("err", err);
        // setResponse("No Barcode!");
      }
    })();
  }, [lambda]);

  const handleNavSelect = (selectedNav) => {
    setActiveNav(selectedNav); // Update activeNav state when a Nav item is clicked
  };
  const renderContent = () => {
    if (value === 1) {
      return (
        <Box
          sx={{
            position: "fixed",
            textAlign: "center",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            startIcon={<PhotoCamera />}
            sx={{ position: "relative", top: "10" }}
            onClick={() => {
              navigate("/cam");
            }}
          >
            Start Scan
          </Button>
        </Box>
      );
    } else if (value === 2) {
      return <Settings />;
    } else if (value === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {caseList && <Recent data={caseList} />}
        </Box>
      );
    }
  };
  useEffect(() => {
    console.log("Status useEffect");
    getSession()
      .then((session) => {
        setStatus(true);
        console.log("Status ", status);
      })
      .catch((err) => {
        //console.log(" Status No user logged in", err);
        setStatus((prev) => !prev);
      });
  }, []);

  const [value, setValue] = React.useState(1);

  return (
    <Box>
      <AppBar color="primary" position="sticky" elevation={5}>
        <Toolbar>
          <Typography variant="h6" color="white" noWrap sx={{ flexGrow: 1 }}>
            SpotCheck
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              setError("");
              logout()
                .then((user) => {
                  // console.log("Logged out!", user)

                  navigate("/");
                })
                .catch((err) => setError(err.message));
            }}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          position: "fixed",
          height: "calc(100% - 128px)",
          top: 64,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {renderContent()}
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default Status;
