import SignUp from "./SignUp";
import Login from "./Login";

import Status from "./Status";
import Layout from "./Layout";
import RequireAuth from "./RequireAuth";
import { Routes, Route } from "react-router-dom";
import Cam from "./Cam";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Login />} />
        <Route path="signup" element={<SignUp />} />

        <Route element={<RequireAuth allowedRoles={["3000"]} />}>
          <Route path="cam" element={<Cam />} />
          <Route path="status" element={<Status />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
