import React, {useState,useRef} from 'react';
import UserPool from './UserPool';

import { Form, Button, Card, Alert, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const SignUp = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const  onSubmit =  (e) => {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }
        
        setError('');
        setLoading(true);
        UserPool.signUp(emailRef.current.value, passwordRef.current.value, [], null, (err, data) => {
            if (err) {
                setError(err.message);
            }
            else
                {
                    console.log(data);
                }
                setLoading(false);
        });
    
        
    };
    return (
        // <div>
        //     <form onSubmit={onSubmit}>
        //         <label htmlFor="email">Email</label>
        //         <input type="email" name="email" 
        //                 value={email} 
        //                 onChange={(e) => setEmail(e.target.value)} />
        //         <label htmlFor="password">Password</label>
        //         <input type="password" name="password" 
        //                 value={password} 
        //                 onChange={(e) => setPassword(e.target.value)} />
        //         <button type="submit">Sign Up</button>
        //     </form>
        // </div>
        <Container style={{maxWidth: "400px"}}>
        <Row className="w-100 text-center mt-2">
         <Card>
            <Card.Body>
                <h2 className='text-center mb-4'>Sign Up</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Form onSubmit={onSubmit}>
                    <Form.Group id='email'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='email' ref={emailRef} required />
                    </Form.Group>
                    <Form.Group id='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' ref={passwordRef} required />
                    </Form.Group>
                    <Form.Group id='password'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type='password' ref={passwordConfirmRef} required />
                    </Form.Group>
                    <Button disabled={loading} className='w-100 mt-3' type='submit'>Sign Up</Button>
                </Form>
            </Card.Body>
         </Card>
        </Row>
        <Row>
         <div className='w-100 text-center mt-2'>
            Already have an account? <Link to = '/login'>Log In</Link>
            </div>
        </Row>
        </Container>
    );
};

export default SignUp;