import React, { createContext } from "react";
import jwtDecode from "jwt-decode";

import Pool from "./UserPool";
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from '@aws-sdk/client-cognito-identity';
const identityPoolId = 'us-east-1:8b2ed160-f875-45e3-9cdc-b53bf3fdc5ac';
const region = 'us-east-1';

const client = new CognitoIdentityClient({ region });



const AccountContext = createContext();
const Account = (props) => {
    const [auth, setAuth] = React.useState({});
    // console.log("AccountAuth", auth)
    if (Object.keys(auth).length === 0) {
        try{
            // console.log("Account.js")
            const user = Pool.getCurrentUser();
            if (user) { console.log("user", user); 
                user.getSession((err, session) => {
                    if (err) {
                        console.log("Error getting session when updating auth: " + err);
                    } else {
                        const sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);
                        const roles = sessionIdInfo['cognito:groups'];
                        setAuth((prev) => ({isAuthenticated: true,roles : roles}));
                    }
                });
            }
        } catch (err) {
            console.log("Error updating auth state: " + err);
        }
    }
    
    const getSession = async () => {
       
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            
            if (user) {
                
                user.getSession(async (err, session) => {
                    if (err) {
                        
                        reject(err);
                    } else {
                        
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err);
                                } else {
                                    const results = {};
                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }
                                    resolve(results);
                                }
                            });
                        });
                        
                        resolve({user,...session,...attributes});
                    }
                });
            } else {
                reject();
            }
        });
    };

    const authenticate = async (Username,Password) => {
        return await new Promise((resolve, reject) => {
            // user is a CognitoUser object that represents the user in the user pool
            const user = new CognitoUser({
                Username,
                Pool,
                Storage: sessionStorage
            });
            // authDetails is an AuthenticationDetails object that represents the user's authentication
            // credentials
            const authDetails = new AuthenticationDetails({
                Username,
                Password,
            });
            
            // authenticateUser() is a method of the CognitoUser object that takes an AuthenticationDetails
            // object and a callback function as parameters
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log('onSuccess:', data);
                    const user = Pool.getCurrentUser();
                    setAuth((prev) => ({...prev, isAuthenticated: true}));
                    if (user) {
                        user.getSession((err, session) => {
                            if (err) {
                                console.log("Error getting session: " + err);
                            } else {
                                const sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);
                                const roles = sessionIdInfo['cognito:groups'];
                                setAuth((prev) => ({...prev, roles}));
                                
                            }
                        });
                    }
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error('onFailure:', err);
                    setAuth(false);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.log('newPasswordRequired:', data);
                    setAuth(false);
                    resolve(data);
                }
            });
        });
    };

    const logout = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                try {user.signOut();
                    setAuth(false);
                    resolve(user);}
                catch (err) {console.log(err);
                    reject(err);}
                
            }});
    };
    return (
        <AccountContext.Provider value={{authenticate, getSession, logout,auth}}>
            {props.children}
        </AccountContext.Provider>
    )
};

export { Account, AccountContext };