import React, { useEffect, useContext, useState } from "react";
import { AccountContext } from "./Account";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./changeEmail";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
export default () => {
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedin] = useState(false);

  useEffect(() => {
    getSession()
      .then((session) => {
        //console.log(" Settings Session", session);
        setLoggedin(true);
      })
      .catch((err) => {
        //console.log("Settings No user logged in", err);
        setLoggedin(false);
      });
  }, []);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" textAlign="center">
        Settings
      </Typography>
      <ChangePassword />
    </Stack>
  );
};
