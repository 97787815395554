import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useState,useEffect, } from "react";

import useAuth from "../hooks/useAuth";

const RequireAuth =  ({allowedRoles}) => {
    const location = useLocation();
    const { auth} = useAuth();
    
    console.log("RequireAuth", auth)
    return (
        auth ?.roles?.find(role => allowedRoles.includes(role))
            ? <Outlet/> 
            : <Navigate to= "" state={{from: location}} replace/>
    );
    }

export default RequireAuth;