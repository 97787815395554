import React from "react";
import { useContext, useState, useRef } from "react";
import { AccountContext } from "./Account";

import { Stack, TextField, Box, Button, Alert, Container } from "@mui/material";
import { Typography } from "@mui/material";

export default () => {
  const passwordRef = useRef();
  const newPasswordRef = useRef();
  const { getSession } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("Changing password...");
    getSession().then(({ user }) => {
      user.changePassword(
        passwordRef.current.value,
        newPasswordRef.current.value,
        (err, result) => {
          if (err) {
            setError(err.message);
          } else {
            console.log(result);
          }
        }
      );
    });
  };
  return (
    <Container maxWidth="sm">
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Stack direction="column" spacing={2} sx={{ width: "70%" }}>
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Old Password"
              type="password"
              inputRef={passwordRef}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              inputRef={newPasswordRef}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
