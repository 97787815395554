import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Camera } from "react-camera-pro";

import { AccountContext } from "./Account";

import { Buffer } from "buffer";

import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { LambdaClient } from "@aws-sdk/client-lambda";
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from "@aws-sdk/client-cognito-identity";

export default function Cam() {
  const { getSession, logout } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [s3, setS3] = useState(null);
  const [lambda, setLambda] = useState(null);
  const [user, setUser] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [client, setClient] = useState(null);
  const [caseNo, setCaseNo] = useState(null);

  const region = "us-east-1";
  const identityPoolId = "us-east-1:8b2ed160-f875-45e3-9cdc-b53bf3fdc5ac";
  const cognitoClient = new CognitoIdentityClient({ region });
  useEffect(() => {
    // const getSession = async () => {
    //     return await new Promise((resolve, reject) => {
    (async () => {
      const Obj = getSession()
        .then((sessionObj) => {
          if (sessionObj) {
            // console.log("sessionObj", sessionObj.user.username);
            setUser(sessionObj.user.username);
            const currentIdentity = cognitoClient
              .send(
                new GetIdCommand({
                  IdentityPoolId: identityPoolId,
                  Logins: {
                    "cognito-idp.us-east-1.amazonaws.com/us-east-1_U4dN05VIF":
                      sessionObj.idToken.jwtToken,
                  },
                })
              )
              .then((data) => {
                // console.log("IdentityId", data.IdentityId);
                const credentials = cognitoClient
                  .send(
                    new GetCredentialsForIdentityCommand({
                      IdentityId: data.IdentityId,
                      Logins: {
                        "cognito-idp.us-east-1.amazonaws.com/us-east-1_U4dN05VIF":
                          sessionObj.idToken.jwtToken,
                      },
                    })
                  )
                  .then((data) => {
                    // console.log("Credentials", data);
                    setIdentity(data.IdentityId);
                    setS3(
                      new S3Client({
                        region: "us-east-1",
                        credentials: {
                          accessKeyId: data.Credentials.AccessKeyId,
                          secretAccessKey: data.Credentials.SecretKey,
                          sessionToken: data.Credentials.SessionToken,
                        },
                      })
                    );
                    setLambda(
                      new LambdaClient({
                        region: "us-east-1",
                        credentials: {
                          accessKeyId: data.Credentials.AccessKeyId,
                          secretAccessKey: data.Credentials.SecretKey,
                          sessionToken: data.Credentials.SessionToken,
                        },
                      })
                    );
                  })
                  .catch((err) => {
                    console.error(err, err.stack);
                    setError(err);
                  });
              })
              .catch((err) => {
                setError(err);
              });
          }
        })
        .catch((err) => {
          console.log("getSession error", err);
          setError(err);
        });
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const [valid, setValid] = useState(false);

  const [dermImage, setDermImage] = useState(null);
  const [dermToggle, setDermToggle] = useState(false);

  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const camera = useRef(null);
  const scanDerm = async () => {
    const image = camera.current.takePhoto();
    setDermImage(image);
    setDermToggle((dermToggle) => !dermToggle);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const steps = ["Barcode Scan", "Photo", "Bottle Scan"];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [barcode, setBarcode] = useState(null);

  useEffect(() => {
    if (barcode !== null) {
      const parts = barcode.split("-");
      setClient(parts[0]); // "Derm Client"
      setCaseNo(parts[1]);

      handleNext();
    }
  }, [barcode]);
  const handleValidation = (e) => {
    const reg = new RegExp("^[A-Z]{3,5}-\\d{6}$");
    console.log("REgex", reg.test(e.target.value));

    if (reg.test(e.target.value)) {
      setBarcode(e.target.value);
      setValid(true);
    } else {
      setValid(false);
      e.target.value = "";
      e.target.focus();
    }
  };

  const [uploadStatus, setUploadStatus] = useState(null);

  const bottleValidation = (e) => {
    const reg = new RegExp("^[A-Z]{3,5}-\\d{6}$");
    console.log("REgex", reg.test(e.target.value));

    if (reg.test(e.target.value)) {
      if (e.target.value === barcode) {
        const barcodeInput = e.target.value;
        const binaryString = Buffer.from(dermImage.split(",")[1], "base64"); // decode the base64 string
        const imageBlob = new Blob([binaryString], { type: "image/jpeg" });
        const bucketName = "derm.images";
        const fileKey = `${identity}/` + barcodeInput + `-` + uuidv4() + `.jpg`;

        const s3Params = {
          Bucket: bucketName,
          Key: fileKey,
          Body: imageBlob,
          ContentType: "image/jpeg",
          Metadata: {
            clientCase: barcodeInput,
            clientUser: user,
          },
        };
        try {
          s3.send(new PutObjectCommand(s3Params), (err, data) => {
            if (err) {
              // console.error(err);
              setUploadStatus("Failed to Upload Image");
            } else {
              // console.log(`Image uploaded to S3 bucket: ${data}`);
              setUploadStatus(null);
              handleNext();
            }
          });
        } catch (err) {
          setUploadStatus("Failed to Upload Image");
        }
      } else {
        setValid(false);
        e.target.value = "";
        e.target.focus();
        setUploadStatus("Barcode does not match ");
      }
    } else {
      setValid(false);
      e.target.value = "";
      e.target.focus();
      setUploadStatus("Invalid Barcode");
    }
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Scan your barcode"
                id="input"
                required
                autoFocus
                autoComplete="off"
                variant="outlined"
                fullWidth
                onChange={(e) => handleValidation(e)}
                error={!valid}
                helperText="Scan a valid barcode"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QrCode2Icon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Box textAlign="center">
            <Button variant="outlined" onClick={handleOpen}>
              Take photo for {barcode}
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid container spacing={1}>
                  {!dermToggle && (
                    <Grid item xs={12} sm={12}>
                      <Camera
                        ref={camera}
                        aspectRatio={3 / 4}
                        facingMode="environment"
                        numberOfCamerasCallback={setNumberOfCameras}
                      />
                    </Grid>
                  )}
                  {dermToggle && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        component="img"
                        sx={{
                          width: "100%",
                        }}
                        src={dermImage}
                      />
                    </Grid>
                  )}
                  {!dermToggle && <Grid item xs={4} sm={4}></Grid>}
                  {!dermToggle && (
                    <Grid item xs={4} sm={4}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={scanDerm}
                        Focus
                      >
                        Take Photo
                      </Button>
                    </Grid>
                  )}

                  {dermToggle && (
                    <Grid item xs={4} sm={4}>
                      <Container maxWidth="sm">
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setDermToggle((dermToggle) => !dermToggle);
                            setDermImage(null);
                          }}
                        >
                          Retake
                        </Button>
                      </Container>
                    </Grid>
                  )}
                  {dermToggle && <Grid item xs={4} sm={4}></Grid>}
                  {dermToggle && (
                    <Grid item xs={4} sm={4}>
                      <Container maxWidth="sm">
                        <Button
                          size="small"
                          variant="contained"
                          onClick={handleNext}
                        >
                          Proceed
                        </Button>
                      </Container>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Modal>
          </Box>
        );
      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                label={"Scan bottle for " + barcode}
                id="input"
                required
                autoFocus
                autoComplete="off"
                variant="outlined"
                fullWidth
                onChange={(e) => bottleValidation(e)}
                error={!valid}
                helperText="Scan a valid barcode"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QrCode2Icon />
                    </InputAdornment>
                  ),
                }}
              />
              {uploadStatus && <Alert severity="error">{uploadStatus}</Alert>}
            </Grid>
          </Grid>
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="App">
      <AppBar
        position="absolute"
        color="primary"
        elevation={5}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="white" noWrap sx={{ flexGrow: 1 }}>
            SpotCheck
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => navigate("/status")}>Home</MenuItem>
              <MenuItem
                onClick={() => {
                  setError("");
                  logout()
                    .then((user) => {
                      // console.log("Logged out!", user)

                      navigate("/");
                    })
                    .catch((err) => setError(err.message));
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Container
        component="main"
        maxWidth="sm"
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ minHeight: "80vh" }}
      >
        <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={3}>
          <Stepper activeStep={activeStep} sx={{ pb: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Alert severity="success" sx={{ textAlign: "center" }}>
                <Typography variant="h5" gutterBottom>
                  Case {barcode} Complete !
                </Typography>
              </Alert>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setActiveStep(0);
                    setDermImage(null);
                    setBarcode(null);
                    setClient(null);
                    setCaseNo(null);
                    setUploadStatus(null);
                    setNumberOfCameras(0);
                    setDermToggle(false);
                    setValid(false);
                    setOpen(false);
                  }}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Start New Case !
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
          )}
        </Paper>
      </Container>
    </div>
  );
}
